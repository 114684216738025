/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file #MyChart button component
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Utilities.ActionButton
 */

import React, { FC, useContext } from "react";
import { StyleContext } from "~/types";
import { resolveClassName } from "~/utils/className";
import styles from "./ActionButton.module.scss";
import { BackgroundStyleContext } from "./BackgroundStyleContext";
import BaseButton, { IButtonProps } from "./BaseButton";

/**
 * Props for ActionButton Component
 */
interface IProps extends IButtonProps {
	/** The tone of the button, hinting at the impact of the action */
	tone: "positive" | "neutral" | "negative";
	/** The importance of the button. Pulse is the most important, and uses primary styling with additional pulsing effects */
	priority: "primary" | "secondary" | "pulse"; // MyChart also has tertiary
	/** Whether or not the button is disabled */
	disabled?: boolean;
	/** Whether or not the button should have narrower padding than default */
	slim?: boolean;
	/** Whether the button is in an active state/pressed */
	active?: boolean;
}

/**
 * The ActionButton component
 * This component is designed to replicate the MyChart action button:
 * https://uxguide/MyChart/components__buttons_mychart.php?mode=standard&version=current&designer=false
 *
 * @param props The props ;)
 */
const ActionButton: FC<IProps> = (props) => {
	const {
		tone,
		priority,
		disabled = false,
		slim = false,
		active = false,
		children,
		...baseButtonProps
	} = props;

	const buttonStyle = useContext(BackgroundStyleContext);

	const visualPriority = priority === "pulse" ? "primary" : priority;
	const isIconOnly = !!(baseButtonProps?.icon && !children && !baseButtonProps?.text);

	const classNames: Record<string, boolean> = {
		actionButton: true,
		[visualPriority]: true,
		disabled,
		dark: buttonStyle === StyleContext.dark,
		slim,
		active,
		pulse: priority === "pulse",
		iconOnly: isIconOnly,
	};
	if (tone !== "neutral") {
		classNames[tone] = true;
	}
	const buttonClassName = resolveClassName(styles, classNames);

	return (
		<BaseButton type="button" disabled={disabled} rawClass={buttonClassName} noStyle {...baseButtonProps}>
			<div tabIndex={-1}>{children}</div>
		</BaseButton>
	);
};

ActionButton.displayName = "ActionButton";

export default ActionButton;
