/**
 * @copyright Copyright 2020-2021 Epic Systems Corporation
 * @file Modal alert for the user making a choice
 * @author Tara Feldstein
 * @module Epic.VideoApp.Components.Alerts.ChoiceAlertBase
 */
import React, { FC, useRef } from "react";
import { useStrings } from "~/hooks";
import { IChoiceAlert, ITestable } from "~/types";
import ActionButton from "../Utilities/ActionButton";
import KeyboardShortcut from "../Utilities/KeyboardShortcut";

import { useGetFormattedHotkeyString } from "~/hooks/useGetFormattedHotkeyString";
import styles from "./Alerts.module.scss";
import FocusTrap from "./FocusTrap";

export enum ChoiceAlertBaseTestIds {
	yesButton = "ChoiceAlertYesButton",
	noButton = "ChoiceAlertNoButton",
}

interface IProps extends ITestable {
	alert: IChoiceAlert;
	confirm: () => void;
	cancel: () => void;
	cancelDisabled?: boolean;
}

enum TokenNames {
	yes = "Yes",
	no = "No",
}

/**
 * Choice alert component
 *
 * Renders a message with two action buttons
 */
const ChoiceAlertBase: FC<IProps> = (props) => {
	const { alert, confirm, cancel, cancelDisabled = false, ...additionalProps } = props;
	const { message, confirmText, confirmHotkey, cancelText, cancelHotkey } = alert;

	const confirmRef = useRef<HTMLButtonElement>(null);
	const cancelRef = useRef<HTMLButtonElement>(null);

	const strings = useStrings("ChoiceAlertBase", Object.values(TokenNames));

	const confirmKeyboardShortcut = confirmHotkey ? ["alt", confirmHotkey] : [];
	const cancelKeyboardShortcut = cancelHotkey ? ["alt", cancelHotkey] : [];
	const yesText = confirmText ?? strings[TokenNames.yes];
	const yesTextFormatted = useGetFormattedHotkeyString(yesText, confirmKeyboardShortcut);
	const noText = cancelText ?? strings[TokenNames.no];
	const noTextFormatted = useGetFormattedHotkeyString(noText, cancelKeyboardShortcut);

	return (
		<div {...additionalProps} className={styles["modalCard"]}>
			<span id="message" className={styles["bodyText"]}>
				{message}
			</span>
			{props.children}
			<div className={styles["buttons"]}>
				<FocusTrap firstElement={confirmRef} lastElement={cancelDisabled ? confirmRef : cancelRef}>
					<ActionButton
						tone={cancelDisabled ? "neutral" : "negative"}
						priority="primary"
						onClick={confirm}
						buttonRef={confirmRef}
						aria-label={yesTextFormatted}
						title={yesTextFormatted}
						data-testid={ChoiceAlertBaseTestIds.yesButton}
					>
						{confirmHotkey ? (
							<KeyboardShortcut
								callback={confirm}
								keyCombination={confirmKeyboardShortcut}
								forModalAlert
								text={yesText}
							/>
						) : (
							yesText
						)}
					</ActionButton>
					{!cancelDisabled && (
						<ActionButton
							tone="neutral"
							priority="primary"
							onClick={cancel}
							buttonRef={cancelRef}
							aria-label={noTextFormatted}
							title={noTextFormatted}
							data-testid={ChoiceAlertBaseTestIds.noButton}
						>
							{cancelHotkey ? (
								<KeyboardShortcut
									callback={cancel}
									keyCombination={cancelKeyboardShortcut}
									forModalAlert
									text={noText}
								/>
							) : (
								noText
							)}
						</ActionButton>
					)}
				</FocusTrap>
			</div>
		</div>
	);
};

ChoiceAlertBase.displayName = "ChoiceAlertBase";

export default ChoiceAlertBase;
