/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Picture in Picture button
 * @author Arun Vasireddy
 * @module Epic.VideoApp.Components.Header.Buttons.PictureInPictureButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useContext, useMemo } from "react";
import ToggleSwitchButton from "~/components/Utilities/ToggleSwitchButton";
import { useStrings } from "~/hooks";
import { useGetFormattedHotkeyString } from "~/hooks/useGetFormattedHotkeyString";
import { pipActions, usePictureInPictureState, useUIState } from "~/state";
import { resolveClassName } from "~/utils/className";
import { getPipIcon } from "~/utils/device";
import { isPictureInPictureSupported } from "~/utils/pictureInPicture";
import { VideoCallContext, VideoContext } from "~/web-core/components";
import { useIsStreamEnabled } from "~/web-core/hooks/useIsStreamEnabled";
import { useStream } from "~/web-core/hooks/useStream";
import { VideoType } from "~/web-core/types";
import styles from "../ControlsHeader.module.scss";
import ControlButton from "./ControlButton";

enum TokenNames {
	ariaLabel = "AriaLabel",
	enterPiPHeader = "EnterPiPHeader",
	leavePiPHeader = "LeavePiPHeader",
	pipDisabled = "PiPDisabled",
	enterPiPParticipant = "EnterPiPParticipant",
	leavePiPParticipant = "LeavePiPParticipant",
}
interface IProps {
	/** Whether or not the button is rendered in the header */
	inHeader: boolean;
	className?: string;
	labelClassName?: string;
	userId?: string;
	videoType?: VideoType;
	noLabel?: boolean;
	toggleSwitchClassName?: string;
}

/**
 * The PictureInPictureButton component
 */
const PictureInPictureButton: FC<IProps> = (props: IProps) => {
	const { inHeader, className, labelClassName, noLabel, toggleSwitchClassName, userId } = props;
	const pinnedVideo = useUIState((selectors) => selectors.getPinnedVideo(), []);
	const videoType = props.videoType ?? pinnedVideo?.videoType;
	const dispatch = useDispatch();
	const participantInPiP = usePictureInPictureState((selectors) => selectors.getPipVideoID(), []);
	const pipVideoType = usePictureInPictureState((selectors) => selectors.getPipVideoType(), []);
	const isPipTrack = userId === participantInPiP && videoType === pipVideoType;
	const isPiPModeEnabled = usePictureInPictureState((selectors) => selectors.getPipStatus(), []);
	const isHeaderButtonPressed = usePictureInPictureState(
		(selectors) => selectors.getHeaderButtonPressed(),
		[],
	);
	const { mainParticipant } = useContext(VideoCallContext);
	const videoStream = useStream(mainParticipant ?? undefined, videoType ?? "camera");
	const isMainParticipantVideoEnabled = useIsStreamEnabled("video", videoStream);
	const isMainParticipantScreenShareEnabled = false;

	const { session } = useContext(VideoContext);
	const isLocal = useMemo(() => {
		return mainParticipant === session?.localUser;
	}, [mainParticipant, session]);

	const disabled =
		(inHeader &&
			!isHeaderButtonPressed &&
			!(
				isMainParticipantVideoEnabled ||
				(pinnedVideo?.videoType === "screen" && isMainParticipantScreenShareEnabled)
			)) ||
		isLocal;

	const strings = useStrings("PictureInPictureButton", Object.values(TokenNames));

	const onLaunchPip = useCallback(() => {
		if (disabled) {
			return;
		}
		let newId = userId;
		let newVideoType = videoType;
		if (inHeader && mainParticipant) {
			const mainId = mainParticipant.getUserIdentity();
			if (pinnedVideo?.identity === mainId) {
				newVideoType = pinnedVideo.videoType;
			} else {
				newVideoType = "camera";
			}
			newId = mainId;
		}
		dispatch(
			pipActions.togglePiPMode({
				videoID: newId,
				videoType: newVideoType,
				fromHeader: inHeader,
			}),
		);
	}, [disabled, dispatch, inHeader, mainParticipant, pinnedVideo, userId, videoType]);

	const pipKeyboardShortcut = ["alt", "k"];
	const tooltipToken = isHeaderButtonPressed ? TokenNames.leavePiPHeader : TokenNames.enterPiPHeader;
	const tooltip = useGetFormattedHotkeyString(strings[tooltipToken], pipKeyboardShortcut);

	//Validate that the feature in supported by the browser before showing the button
	if (!isPictureInPictureSupported()) {
		return null;
	}
	const buttonClassName = resolveClassName(styles, { controlButton: true, default: true }, className);

	return inHeader ? (
		<ControlButton
			icon={getPipIcon(isHeaderButtonPressed)}
			onClick={onLaunchPip}
			keyboardShortcut={pipKeyboardShortcut}
			buttonText={!noLabel ? (disabled ? strings[TokenNames.pipDisabled] : strings[tooltipToken]) : ""}
			tooltipText={noLabel ? (disabled ? strings[TokenNames.pipDisabled] : tooltip) : ""}
			ariaLabel={tooltip}
			buttonClassName={disabled ? styles["lockedAndDisabled"] : styles["default"]}
			aria-disabled={disabled}
		/>
	) : (
		<ToggleSwitchButton
			text={strings[TokenNames.enterPiPParticipant]}
			className={buttonClassName}
			onClick={onLaunchPip}
			on={isPiPModeEnabled && isPipTrack}
			labelClassName={labelClassName}
			toggleSwitchClassName={toggleSwitchClassName}
			id="pipButton"
		/>
	);
};

PictureInPictureButton.displayName = "PictureInPictureButton";

export default PictureInPictureButton;
