/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file New Hardware Test preview panel with video preview, toggle buttons, test audio, and name input
 * @author Max Harkins
 * @module Epic.VideoApp.Components.HardwareTest.HardwarePreviewPanel
 */

import React, { FC, PropsWithChildren } from "react";
import { useWindowSize } from "~/hooks";
import { ScreenWidthInflectionPoint } from "~/types";
import DisplayNameInput from "../HardwareSetup/DisplayNameInput";
import { useAudioDeviceMessage } from "../HardwareSetup/hooks/useAudioDeviceMessage";
import { useCameraDeviceMessage } from "../HardwareSetup/hooks/useCameraDeviceMessage";
import { useSpeakerDeviceMessage } from "../HardwareSetup/hooks/useSpeakerDeviceMessage";
import SkipHardwareTestSection from "../HardwareTest/SkipHardwareTestSection";
import HardwareStatusBanner from "./Banners/HardwareStatusBanner";
import styles from "./HardwareTest.module.scss";
import InlineVideoPreview from "./InlineVideoPreview";
import ToggleRow from "./ToggleRow";

interface IProps {
	isLobby?: boolean;
}

/**
 * The HardwarePreviewPanel component
 *
 * Shows the video and mic preview, toggle controls, name input, and status messages for the hardware test
 * @param props the props ;)
 */
const HardwarePreviewPanel: FC<IProps> = (props: PropsWithChildren<IProps>) => {
	const { isLobby, children } = props;

	const { width } = useWindowSize();

	// For message panel
	const { message: speakerMessage, onSpeakerTest } = useSpeakerDeviceMessage();
	const audioMessage = useAudioDeviceMessage();
	const cameraMessage = useCameraDeviceMessage();

	return (
		<div className={styles["actionContent"]}>
			{width > ScreenWidthInflectionPoint ? (
				<div className={styles["videoAndToggles"]}>
					<InlineVideoPreview />
					<ToggleRow onSpeakerTest={onSpeakerTest} />
				</div>
			) : (
				<>
					<InlineVideoPreview />
					<div className={styles["mobileToggleRow"]}>
						<ToggleRow onSpeakerTest={onSpeakerTest} />
					</div>
				</>
			)}
			<HardwareStatusBanner
				isLobby={isLobby}
				speakerMessage={speakerMessage}
				audioMessage={audioMessage}
				cameraMessage={cameraMessage}
			/>
			<SkipHardwareTestSection styling="lobby" />
			<div className={styles["nameAndVideoContainer"]}>
				{isLobby && <DisplayNameInput disabled={false} />}
				{children}
			</div>
		</div>
	);
};

HardwarePreviewPanel.displayName = "HardwarePreviewPanel";

export default HardwarePreviewPanel;
