/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file Background Gallery Option
 * @author Liam Liden
 * @module Epic.VideoApp.Components.BackgroundSelector.BackgroundGalleryOption
 */

import React, { FC, useCallback, useContext, useMemo } from "react";
import { useBackgroundToggle } from "~/hooks/backgroundEffects/useBackgroundToggle";
import { StyleContext } from "~/types";
import { BackgroundResourceType } from "~/types/backgrounds";
import { resolveClassName } from "~/utils/className";
import { makeResourceLink } from "~/utils/general";
import { warn } from "~/utils/logging";
import { ILocalStream } from "~/web-core/interfaces";
import { BackgroundStyleContext } from "../Utilities/BackgroundStyleContext";
import { MenuContext } from "../Utilities/MenuContext";
import styles from "./BackgroundGalleryOption.module.scss";

interface IProps {
	/** Image to be shown in the gallery and used as virtual background if !blur */
	image: string;

	/** Alt text for the image */
	alt: string;

	/** Preview stream to apply processor to. Applies to deviceStream if not provided */
	previewStream?: ILocalStream;

	/** Optional text to place on the option's banner; option will not have a banner if not given */
	bannerText?: string;

	/**
	 * Type of resource being loaded into the gallery. For example, a resource could be a local Epic-released
	 * resource or a branded virtual background.
	 */
	resourceType?: BackgroundResourceType;
}

const BackgroundGalleryOption: FC<IProps> = (props: IProps) => {
	const { image, alt, previewStream, bannerText, resourceType } = props;
	const { isEnabled, toggleProcessor } = useBackgroundToggle(image, previewStream);

	const isInDarkMode = useContext(BackgroundStyleContext) === StyleContext.dark;

	const { isInMenu: isFromMenu } = useContext(MenuContext);
	if (!isFromMenu) {
		warn("BackgroundGalleryOption displayed from non-menu context");
	}

	const selectedClassName = resolveClassName(styles, {
		galleryOption: true,
		selected: isEnabled,
		isInDarkMode: isInDarkMode,
	});

	const wrapperClasName = resolveClassName(styles, {
		optionWrapper: true,
		selected: isEnabled,
	});

	const onClick = useCallback(() => {
		toggleProcessor("on");
	}, [toggleProcessor]);

	const imageSource = useMemo(() => {
		if (resourceType === "RemoteImage") {
			return makeResourceLink("RemoteImage", image);
		}
		const thumbnailPath = image.replace(".png", "_thumb.png");
		return makeResourceLink("BackgroundImage", thumbnailPath);
	}, [image, resourceType]);

	return (
		<div className={wrapperClasName}>
			<input
				role="menuitemradio"
				aria-label={bannerText ?? alt}
				aria-checked={isEnabled}
				className={selectedClassName}
				type="image"
				src={imageSource}
				alt={alt}
				onClick={onClick}
			/>
			{bannerText && (
				<span aria-hidden="true" className={styles["bannerText"]}>
					{bannerText}
				</span>
			)}
		</div>
	);
};

BackgroundGalleryOption.displayName = "BackgroundGalleryOption";

export default BackgroundGalleryOption;
