/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Hardware Setup component
 * @author Matt Panico
 * @module Epic.VideoApp.Components.HardwareSetup.HardwareSetup
 */

import React, { FC } from "react";
import { useHardwareTestState } from "~/state";
import { useCombinedSelectors } from "~/state/combined";
import { DeviceStatus, DisplayContext } from "~/types";
import { resolveClassName } from "~/utils/className";
import BackgroundGalleryToggleRow, {
	BackgroundGalleryToggleRowTestIds,
} from "../BackgroundSelector/BackgroundGalleryToggleRow";
import SkipHardwareTestSection, {
	SkipHardwareTestSectionTestIds,
} from "../HardwareTest/SkipHardwareTestSection";
import AudioWarningBanner from "./AudioWarningBanner";
import CameraSetup, { CameraSetupTestIds } from "./Devices/CameraSetup";
import MicSetup, { MicSetupTestIds } from "./Devices/MicSetup";
import SpeakerSetup, { SpeakerSetupTestIds } from "./Devices/SpeakerSetup";
import DisplayNameInput from "./DisplayNameInput";
import styles from "./HardwareSetup.module.scss";

interface IProps {
	context: DisplayContext;
	className?: string;
	disabled?: boolean;
}

export enum HardwareSetupTestIds {
	camera = CameraSetupTestIds.self,
	microphone = MicSetupTestIds.self,
	speaker = SpeakerSetupTestIds.self,
	skipHardwareTest = SkipHardwareTestSectionTestIds.self,
	backgroundGalleryToggleRow = BackgroundGalleryToggleRowTestIds.label,
}

// create a context for what context the hardware setup is being displayed in to avoid having to this prop through all children
export const HardwareSetupDisplayContext = React.createContext(DisplayContext.standAlone);

/**
 * The HardwareSetup component
 * @param props The props ;)
 */
const HardwareSetup: FC<IProps> = (props: IProps) => {
	const { context, className, disabled } = props;

	const isCameraSuccess =
		useHardwareTestState((selectors) => selectors.getCameraStatus(false), []) === DeviceStatus.success;
	const isBackgroundsSupported = useCombinedSelectors(
		(selectors) => selectors.getIsBackgroundsSupported(),
		[],
	);

	const inDevicesTray = context === DisplayContext.devicesTray;
	const wrapperClassName = resolveClassName(
		styles,
		{ hardwareSetupRows: true, dark: inDevicesTray },
		className,
	);

	return (
		<HardwareSetupDisplayContext.Provider value={context}>
			<div className={wrapperClassName}>
				<AudioWarningBanner />
				<CameraSetup />
				{context === DisplayContext.lobby && isBackgroundsSupported && isCameraSuccess && (
					<BackgroundGalleryToggleRow />
				)}
				<MicSetup />
				<SpeakerSetup />
				{context === DisplayContext.devicesTray && (
					<SkipHardwareTestSection context={context} styling="deviceTray" />
				)}
				<DisplayNameInput disabled={disabled} />
			</div>
		</HardwareSetupDisplayContext.Provider>
	);
};

HardwareSetup.displayName = "HardwareSetup";

export default HardwareSetup;
