/**
 * @copyright Copyright 2021-2023 Epic Systems Corporation
 * @file Nameplate used to display user's name and status
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Participants.ParticipantNameBar
 */

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import ClickOutsideSection from "~/components/Utilities/ClickOutsideSection";
import { useStrings } from "~/hooks";
import Pin from "~/icons/pin";
import { uiActions, useRoomState, useUIState, useUserState } from "~/state";
import { IParticipantVideo } from "~/types";
import { resolveClassName } from "~/utils/className";
import { getMicIcon } from "~/utils/device";
import { isMobile } from "~/utils/os";
import { SharedStringTokens, stringFormat } from "~/utils/strings";
import { useIsStreamEnabled } from "~/web-core/hooks/useIsStreamEnabled";
import { useStream } from "~/web-core/hooks/useStream";
import { IUser } from "~/web-core/interfaces";
import { VideoType } from "~/web-core/types";
import AudioAnalyzer from "./Audio/AudioAnalyzer";
import ParticipantControlMenu from "./Moderation/ParticipantControlMenu";
import ShowMoreButton from "./Moderation/ShowMoreButton";
import styles from "./ParticipantNameBar.module.scss";
import ScreenShareAudioIndicator from "./ScreenShareAudioIndicator";
import { useParticipantName } from "./hooks/useParticipantName";

export type NameBarLocation = "main" | "preview" | "participant-strip";

/** String tokens used by the ParticipantNameBar component */
export enum TokenNames {
	clickToPinVideo = "ClickToPinVideo",
	clickToUnpinVideo = "ClickToUnpinVideo",
	clickToPinScreen = "ClickToPinScreen",
	clickToUnpinScreen = "ClickToUnpinScreen",
	youAreMuted = "YouAreMuted",
	callerIsMuted = "CallerIsMuted",
	callerIsMutedWithName = "CallerIsMutedWithName",
	yourVideo = "YourVideo",
	videoOfAnotherPerson = "VideoOfAnotherPerson",
	videoOfAnotherPersonWithName = "VideoOfAnotherPersonWithName",
	screenOfAnotherPerson = "ScreenOfAnotherPerson",
	screenOfAnotherPersonWithName = "ScreenOfAnotherPersonWithName",
	sharingAudio = "SharingAudio",
	muted = "Muted",
	pinYourVideo = "PinYourVideo",
	pinAnotherVideo = "PinAnotherVideo",
	pinAnotherVideoWithName = "PinAnotherVideoWithName",
	pinAnotherScreen = "PinAnotherScreen",
	pinAnotherScreenWithName = "PinAnotherScreenWithName",
}

/**
 * Props for ParticipantNameBar Component
 */
interface INameBarProps {
	participant: IUser;
	isLocal?: boolean;
	className?: string;
	disablePinning?: boolean;
	isHidden?: boolean;
	location?: NameBarLocation;
	videoType: VideoType;
}

export enum ParticipantNameBarTestIds {
	self = "ParticipantNameBar",
	nameLabel = "NameLabel",
}

/**
 * The ParticipantNameBar component
 * @param props The props ;)
 */
const ParticipantNameBar: FC<INameBarProps> = (props) => {
	const {
		isLocal = false,
		participant,
		className,
		disablePinning = false,
		isHidden = false,
		location,
		videoType,
	} = props;
	const pinnedVideo = useUIState((selectors) => selectors.getPinnedVideo(), []);
	const lastFocusedParticipant = useUIState((selectors) => selectors.getLastFocusedParticipant(), []);
	const waitingForFocus = useUIState((selectors) => selectors.getWaitingForFocus(), []);
	const displayName = useParticipantName(participant, isLocal);
	const strings = useStrings("ParticipantNameBar", Object.values(TokenNames), [
		SharedStringTokens.localParticipantIndicator,
		SharedStringTokens.localScreenShareIndicator,
	]);

	const deviceStream = useStream(participant, "camera");
	const screenShareStream = useStream(participant, "screen");
	const isVideoEnabled = useIsStreamEnabled("video", deviceStream);
	const isMicEnabled = useIsStreamEnabled("audio", deviceStream);
	const isScreenShareEnabled = useIsStreamEnabled("video", screenShareStream);
	const isSharingAudio = useIsStreamEnabled("audio", screenShareStream);

	const [isMuted, setIsMuted] = useState(false);
	const [isSpeaking, setIsSpeaking] = useState(false);

	const dispatch = useDispatch();
	const pinRef = useRef<HTMLButtonElement>(null);
	const participantRef = useRef<IParticipantVideo | null>(null);
	const id = participant.getUserIdentity();

	const participantModeration = useRoomState(
		(selectors) => selectors.getParticipantModerationLocks(id),
		[id],
	);
	const localLocked = useUserState((selectors) => selectors.getMicLock(), []);
	const { audio: remoteLocked } = participantModeration;
	const isLocked = remoteLocked || (isLocal && localLocked);

	const onFocus = useCallback(() => {
		// Keep track of the participant that has been tabbed into or clicked on
		const video = { identity: id, videoType };
		dispatch(uiActions.setLastFocusedParticipant(video));
		participantRef.current = video;
		// Clears waitingForFocus
		dispatch(uiActions.setWaitingForFocus(false));
	}, [dispatch, id, videoType]);

	const onBlur = useCallback(() => {
		// Clear last focused participant when tabbing out or placing focus on a component that's not a participant
		dispatch(uiActions.setLastFocusedParticipant(null));
		participantRef.current = null;
	}, [dispatch, participantRef]);

	const onPinClick = useCallback(() => {
		// Sets waitingForFocus when a participant is pinned or unpinned
		dispatch(
			uiActions.toggleVideoPinned({
				pinnedVideo: { identity: id, videoType: videoType },
				updateSource: "Default",
			}),
		);
		dispatch(uiActions.setWaitingForFocus(true));
	}, [dispatch, id, videoType]);

	// Callback to prevent clicking the name bar from pinning
	const preventDefault = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
	}, []);

	const [menuActive, setMenu] = useState<boolean>(false);

	const showMenu = useCallback(
		(event?: React.MouseEvent<HTMLButtonElement>) => {
			setMenu(!menuActive);

			event?.stopPropagation();
		},
		[menuActive],
	);

	useEffect(() => {
		// If the component unmounts while it was focused, then it will set waitingForFocus to true
		// This happens when a new caller joins while the main participant is focused
		return () => {
			if (participantRef.current?.identity === id && participantRef.current?.videoType === videoType) {
				dispatch(uiActions.setWaitingForFocus(true));
			}
		};
	}, [dispatch, participantRef, participant, videoType, id]);

	useEffect(() => {
		// If the component becomes hidden while it was focused, then it will set waitingForFocus to true
		if (
			isHidden &&
			lastFocusedParticipant?.identity === id &&
			lastFocusedParticipant.videoType === videoType
		) {
			dispatch(uiActions.setWaitingForFocus(true));
		}
	}, [dispatch, id, isHidden, lastFocusedParticipant, participant, videoType]);

	useEffect(() => {
		// focus on current pin if waitingForFocus flag is set and current participant matches the last focused participant
		if (
			waitingForFocus &&
			lastFocusedParticipant?.identity === id &&
			lastFocusedParticipant.videoType === videoType &&
			!isHidden
		) {
			pinRef.current?.focus();
		}
	}, [dispatch, waitingForFocus, lastFocusedParticipant, participant, videoType, isHidden, id]);

	const onIsAudioTrackEnabledChanged = useCallback((isAudioEnabled: boolean) => {
		setIsMuted(!isAudioEnabled);
	}, []);

	useEffect(() => {
		// the name bar doesn't completely unmount for the main participant, so ensure state is reset for screens
		if (videoType === "screen") {
			setIsMuted(false);
			setIsSpeaking(false);
		}
	}, [videoType]);

	const nameBarClassName = resolveClassName(
		styles,
		{
			infoBar: true,
			main: location === "main",
			localPreview: location === "preview",
			menuActive: menuActive,
		},
		className,
	);

	const wrapperClassName = resolveClassName(
		styles,
		{
			wrapper: true,
			speaking: isSpeaking,
			menuOpen: menuActive,
		},
		className,
	);

	const showMoreButtonClassName = resolveClassName(styles, { dots: true, open: menuActive });

	const isPinned = pinnedVideo?.identity === id && pinnedVideo.videoType === videoType;
	const pinClassName = resolveClassName(styles, {
		pinButton: true,
		nameBarButton: true,
		pinned: isPinned,
		mobile: isMobile(),
	});

	const buttonDivClassName = resolveClassName(styles, {
		spacer: true,
		buttonDiv: true,
	});

	let pinTitleText: string;
	if (videoType === "screen") {
		pinTitleText = isPinned
			? strings[TokenNames.clickToUnpinScreen]
			: strings[TokenNames.clickToPinScreen];
	} else {
		pinTitleText = isPinned ? strings[TokenNames.clickToUnpinVideo] : strings[TokenNames.clickToPinVideo];
	}

	// This is a band-aid fix for dealing with the complexity of this file, but a total refactor is needed
	const [accessibleLabel, nameBarLabel] = constructAccessibleLabel(
		strings,
		isLocal,
		videoType,
		displayName,
		isMuted,
		isSharingAudio,
		disablePinning,
	);

	const mutedTitleText = isLocal
		? strings[TokenNames.youAreMuted]
		: displayName
		? stringFormat(strings[TokenNames.callerIsMutedWithName], displayName)
		: strings[TokenNames.callerIsMuted];

	return (
		<div
			data-testid={ParticipantNameBarTestIds.self}
			className={wrapperClassName}
			onClick={preventDefault}
		>
			<div className={nameBarClassName}>
				<span
					data-testid={ParticipantNameBarTestIds.nameLabel}
					className={styles["participantName"]}
					title={displayName}
					aria-hidden
				>
					{nameBarLabel !== undefined && nameBarLabel}
				</span>

				{videoType === "screen" && (
					<ScreenShareAudioIndicator
						participant={participant}
						displayName={displayName}
						isLocal={isLocal}
					/>
				)}

				{videoType !== "screen" && !isMicEnabled && (
					<div className={styles["spacer"]} title={mutedTitleText} aria-hidden>
						{React.createElement(getMicIcon(false, isLocked))}
					</div>
				)}

				{disablePinning ? (
					<label className={styles["nameBarAccessibleLabel"]}>{accessibleLabel}</label>
				) : (
					<button
						className={pinClassName}
						ref={pinRef}
						onFocus={onFocus}
						onBlur={onBlur}
						onClick={onPinClick}
						aria-pressed={isPinned}
						aria-label={accessibleLabel}
					>
						<div tabIndex={-1} className={buttonDivClassName} title={pinTitleText}>
							<Pin aria-hidden />
						</div>
					</button>
				)}

				<ShowMoreButton
					className={showMoreButtonClassName}
					onClick={showMenu}
					isLocal={isLocal}
					pressed={menuActive}
					isTrackEnabled={
						(videoType === "camera" && isVideoEnabled) ||
						(videoType === "screen" && isScreenShareEnabled)
					}
				/>
			</div>

			<AudioAnalyzer
				participant={participant}
				onIsTrackEnabledChanged={onIsAudioTrackEnabledChanged}
				onSpeakingChange={setIsSpeaking}
				isScreenShare={videoType === "screen"}
			/>
			{menuActive && (
				<div className={styles["controlBar"]}>
					<ClickOutsideSection onClickOutside={showMenu}>
						<ParticipantControlMenu
							participant={participant}
							videoType={videoType}
							name={nameBarLabel}
							inNameBar
							location={location}
						/>
					</ClickOutsideSection>
				</div>
			)}
		</div>
	);
};

// Returns the [accessibleLabel, nameBarLabel] for the participant
function constructAccessibleLabel(
	strings: Record<string, string>,
	isLocal: boolean,
	videoType: VideoType,
	displayName: string | undefined,
	isMuted: boolean,
	isSharingAudio: boolean,
	disablePinning: boolean,
): [string, string | undefined] {
	let accessibleLabel: string | undefined;
	let accessiblePinLabel: string | undefined;
	let nameBarLabel: string | undefined;
	if (isLocal) {
		accessiblePinLabel = strings[TokenNames.pinYourVideo];
		if (videoType === "screen") {
			accessibleLabel = strings[SharedStringTokens.localScreenShareIndicator];
			nameBarLabel = strings[SharedStringTokens.localScreenShareIndicator];
		} else {
			accessibleLabel = strings[TokenNames.yourVideo];
			nameBarLabel = stringFormat(strings[SharedStringTokens.localParticipantIndicator], displayName);
		}
	} else if (videoType === "screen") {
		if (displayName) {
			accessibleLabel = stringFormat(strings[TokenNames.screenOfAnotherPersonWithName], displayName);
			accessiblePinLabel = stringFormat(strings[TokenNames.pinAnotherScreenWithName], displayName);
			nameBarLabel = displayName;
		} else {
			accessibleLabel = strings[TokenNames.screenOfAnotherPerson];
			accessiblePinLabel = strings[TokenNames.pinAnotherScreen];
		}
	} else {
		if (displayName) {
			accessibleLabel = stringFormat(strings[TokenNames.videoOfAnotherPersonWithName], displayName);
			accessiblePinLabel = stringFormat(strings[TokenNames.pinAnotherVideoWithName], displayName);
			nameBarLabel = displayName;
		} else {
			accessibleLabel = strings[TokenNames.videoOfAnotherPerson];
			accessiblePinLabel = strings[TokenNames.pinAnotherVideo];
		}
	}

	if (isMuted) {
		accessibleLabel += " " + strings[TokenNames.muted];
	}

	if (isSharingAudio) {
		accessibleLabel += " " + strings[TokenNames.sharingAudio];
	}

	if (!disablePinning) {
		accessibleLabel += " " + accessiblePinLabel;
	}

	return [accessibleLabel, nameBarLabel];
}

ParticipantNameBar.displayName = "ParticipantNameBar";

export default ParticipantNameBar;
