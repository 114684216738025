/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file information circle icon component
 * @author Colin Walters
 * @module Epic.VideoApp.Icons.InformationCircle
 */

import React, { FC } from "react";
import { IconProps } from ".";

/**
 * Props for InformationCircle Component
 */
interface IProps extends IconProps {
	/** The color of the 'i'*/
	fillColor?: string;
	/** The background color of the icon*/
	baseColor?: string;
	/** The outline color of the circle */
	outlineColor?: string;
}

/**
 * The InformationCircle component
 * @param props The props ;)
 */
const InformationCircle: FC<IProps> = (props) => {
	const { fillColor, baseColor, outlineColor, ...iconProps } = props;

	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...iconProps}>
			<circle
				fill={baseColor ?? "#246fb5"}
				stroke={outlineColor}
				strokeWidth="2"
				cx="32"
				cy="32"
				r="28"
			/>
			<rect fill={fillColor ?? "#fff"} x="28" y="29" width="8" height="22" />
			<circle fill={fillColor ?? "#fff"} cx="32" cy="19.23" r="5" />
		</svg>
	);
};

InformationCircle.displayName = "InformationCircle";

export default InformationCircle;
