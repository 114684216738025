/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Panel holding settings for the hardware test
 * @author Max Harkins
 * @module Epic.VideoApp.Components.HardwareTest.SettingsPanel
 */

import React, { FC } from "react";
import { useStrings } from "~/hooks";
import { useHardwareTestState } from "~/state";
import { HardwareTestTab } from "~/types";
import { resolveClassName } from "~/utils/className";
import BackgroundGalleryToggleRow from "../BackgroundSelector/BackgroundGalleryToggleRow";
import CameraSetup from "../HardwareSetup/Devices/CameraSetup";
import MicSetup from "../HardwareSetup/Devices/MicSetup";
import SpeakerSetup from "../HardwareSetup/Devices/SpeakerSetup";
import HardwareTestMenu from "./HardwareTestMenu";
import styles from "./SettingsPanel.module.scss";

enum TokenNames {
	settingsLabel = "SettingsLabel",
	backgroundsLabel = "BackgroundsLabel",
}

/**
 * The Settings Panel
 */
const SettingsPanel: FC = () => {
	const currentTab = useHardwareTestState((selectors) => selectors.getHardwareTestTab(), []);

	const strings = useStrings("SettingsPanel", Object.values(TokenNames));

	const panelClassName = resolveClassName(styles, {
		settingsPanel: true,
		panelClosed: currentTab.tab === HardwareTestTab.closed,
	});

	return (
		<div className={panelClassName}>
			<div className={styles["panelContentHolder"]}>
				<div className={styles["tabContent"]}>
					<HardwareTestMenu
						tabType={HardwareTestTab.devices}
						id="settingsMenu"
						label={strings[TokenNames.settingsLabel]}
						visible={currentTab.tab === HardwareTestTab.devices}
						needsAutoFocus={!currentTab.isAutoSelected}
					>
						<CameraSetup />
						<MicSetup />
						<SpeakerSetup />
					</HardwareTestMenu>
					<HardwareTestMenu
						tabType={HardwareTestTab.backgrounds}
						id="backgroundsMenu"
						label={strings[TokenNames.backgroundsLabel]}
						visible={currentTab.tab === HardwareTestTab.backgrounds}
						needsAutoFocus={!currentTab.isAutoSelected}
					>
						<BackgroundGalleryToggleRow />
					</HardwareTestMenu>
				</div>
			</div>
		</div>
	);
};

export default SettingsPanel;
