/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file error icon
 * @author Colin Walters
 * @module Epic.VideoApp.Icons.Error
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

/**
 * The Error component
 * @param props The props ;)
 */
const Error: FC<IconProps> = memo((props) => {
	const { title } = props;
	return (
		<svg className="error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160 160" {...props}>
			{title && <title>{title}</title>}
			<g>
				<path d="M112.343 31.771l16.115 16.115-32.229 32.457 32.343 32.343-16.114 16.114L80 96.458l-32.229 32.228-16.114-16.114 32.229-32.229L31.543 48l16.114-16.114L80 64.114l32.343-32.343z" />
			</g>
		</svg>
	);
});

Error.displayName = "Error";

export default Error;
