/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Toast buttons for admitting participants
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Header.Toasts.Buttons.HandleAdmissionButtonGroup
 */

import React, { FC, useCallback, useContext } from "react";
import ActionButton from "~/components/Utilities/ActionButton";
import { useStrings } from "~/hooks";
import { useSendVisitAccessMessage } from "~/hooks/messaging";
import { VideoCallContext } from "../../../../web-core/components/VideoCallProvider";
import { useParticipantName } from "../../../Participants/hooks/useParticipantName";
import OpenMenuButton from "./OpenMenuButton";

export enum HandleAdmissionButtonGroupTestIds {
	admitButton = "AdmitButton",
}

enum TokenNames {
	admit = "AdmitButton",
	openMenu = "OpenMenu",
}

interface IToastProps {
	identity: string;
}

/**
 * The HandleAdmissionButtonGroup component
 * @param props The props ;)
 */
const HandleAdmissionButtonGroup: FC<IToastProps> = (props) => {
	const { identity } = props;
	const strings = useStrings("Toasts", Object.values(TokenNames));
	const allowAccess = useSendVisitAccessMessage();
	const { waitingParticipants } = useContext(VideoCallContext);
	const waitingParticipant = waitingParticipants.find((p) => p.getUserIdentity() === identity);
	const displayName = useParticipantName(waitingParticipant ?? null);

	const onAllow = useCallback(() => {
		void allowAccess(identity, displayName);
	}, [allowAccess, displayName, identity]);

	return (
		<>
			<ActionButton
				tone="positive"
				priority="primary"
				onClick={onAllow}
				text={strings[TokenNames.admit]}
				data-testid={HandleAdmissionButtonGroupTestIds.admitButton}
			/>
			<OpenMenuButton menu="participants" />
		</>
	);
};
HandleAdmissionButtonGroup.displayName = "HandleAdmissionButtonGroup";

export default React.memo(HandleAdmissionButtonGroup);
