/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file toggle switch component
 * @author Colin Walters
 * @module Epic.VideoApp.Components.HardwareSetup.Devices.ToggleSwitch
 */

import React, { FC, useRef } from "react";
import KeyboardShortcut from "~/components/Utilities/KeyboardShortcut";
import Tooltip, { ITooltipInfo } from "~/components/Utilities/Tooltip";
import Success from "~/icons/success";
import { resolveClassName } from "~/utils/className";
import styles from "./ToggleSwitch.module.scss";

/**
 * Props for ToggleSwitch Component
 */
interface IProps {
	/* The on/off state of the switch */
	on: boolean;
	/* Function to call when the switch is toggled */
	onToggle: () => void;
	/* Accessible label for the switch */
	ariaLabel: string;
	/* Keyboard shortcut for the toggle */
	keyboardShortcut?: string[];
	/* If the control should be disabled */
	disabled?: boolean;
	/* Tooltip info to construct a tooltip */
	tooltipInfo?: ITooltipInfo;
}

export enum ToggleSwitchTestIds {
	self = "ToggleSwitch",
}

/**
 * The ToggleSwitch component
 * @param _props The props ;)
 */
const ToggleSwitch: FC<IProps> = (props) => {
	const { on, onToggle, ariaLabel, keyboardShortcut, disabled, tooltipInfo } = props;

	const wrapperClassName = resolveClassName(styles, { wrapper: true, on });

	const buttonRef = useRef<HTMLButtonElement>(null);

	// make this a button so enter & space will work when focused
	return (
		<button
			className={wrapperClassName}
			onClick={onToggle}
			aria-label={ariaLabel}
			aria-checked={on}
			role="switch"
			disabled={disabled}
			ref={buttonRef}
			data-testid={ToggleSwitchTestIds.self}
		>
			{!disabled && keyboardShortcut && (
				<KeyboardShortcut callback={onToggle} keyCombination={keyboardShortcut} />
			)}
			<div tabIndex={-1}>
				<Success className={styles["onIcon"]} aria-hidden />
				<div className={styles["slider"]} />
				{!disabled && tooltipInfo && (
					<Tooltip elementWithTooltip={buttonRef.current} {...tooltipInfo} />
				)}
			</div>
		</button>
	);
};

ToggleSwitch.displayName = "ToggleSwitch";

export default ToggleSwitch;
