/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file background gallery
 * @author Liam Liden
 * @module Epic.VideoApp.Components.BackgroundSelector.BackgroundGallery
 */

import React, { FC, useMemo } from "react";
import { useStrings, useWindowSize } from "~/hooks";
import { useBackgroundProcessorsState } from "~/state/backgroundProcessors";
import { ScreenWidthInflectionPoint, SmallScreenWidthInflectionPoint } from "~/types";
import {
	DefaultImageNames,
	DesktopElements,
	DesktopInflectionElements,
	ICustomBackgroundProcessor,
	SmallInflectionElements,
} from "~/types/backgrounds";
import { stringFormat } from "~/utils/strings";
import { ILocalStream } from "~/web-core/interfaces";
import Spinner from "../Loading/Spinner";
import Carousel from "../Utilities/Carousel/Carousel";
import { MenuContextProvider } from "../Utilities/MenuContext";
import styles from "./BackgroundGallery.module.scss";
import BackgroundGalleryNoneOption from "./BackgroundGalleryNoneOption";
import BackgroundGalleryOption from "./BackgroundGalleryOption";

interface IProps {
	/** If given, applies background processors to previewTrack rather than the published track */
	previewStream?: ILocalStream;

	/** If set, displays gallery in scroll mode instead of carousel */
	scrollGallery?: boolean;
}

enum TokenNames {
	backgroundEffectAltText = "BackgroundEffectAltText",
	backgroundEffectAltTextDefault = "BackgroundEffectAltTextDefault",
	blur = "Blur",
	none = "None",
	noneAriaLabel = "NoneAriaLabel",
	office = "Office",
	dark = "Dark",
	planks = "Planks",
	space = "Space",
	spring = "Spring",
	summer = "Summer",
	fall = "Fall",
	winter = "Winter",
}

export const backgroundToToken: Record<string, string> = {
	[DefaultImageNames.blur]: TokenNames.blur,
	[DefaultImageNames.office]: TokenNames.office,
	[DefaultImageNames.dark]: TokenNames.dark,
	[DefaultImageNames.planks]: TokenNames.planks,
	[DefaultImageNames.space]: TokenNames.space,
	[DefaultImageNames.spring]: TokenNames.spring,
	[DefaultImageNames.summer]: TokenNames.summer,
	[DefaultImageNames.fall]: TokenNames.fall,
	[DefaultImageNames.winter]: TokenNames.winter,
};

const getAltForImage = (
	path: string,
	processor: ICustomBackgroundProcessor,
	strings: Record<string, string>,
): string => {
	let imageDescription: string | null = null;

	// Try to get a translated string first
	const stringToken = path in backgroundToToken ? backgroundToToken[path] : null;
	if (stringToken) {
		imageDescription = strings[stringToken];
	} else {
		// Fall back to parsing the description from the path/name
		const matches = imageDescriptionRegex.exec(processor.name ?? path);
		if (matches) {
			imageDescription = matches[0];
		}
	}

	return imageDescription
		? stringFormat(strings[TokenNames.backgroundEffectAltText], imageDescription)
		: strings[TokenNames.backgroundEffectAltTextDefault];
};

const imageDescriptionRegex = new RegExp(/([a-zA-Z ]+)/);

const BackgroundGallery: FC<IProps> = (props: IProps) => {
	const { previewStream, scrollGallery } = props;
	const processorMap = useBackgroundProcessorsState((selectors) => selectors.getBackgroundProcessors(), []);
	const processorLoadStatus = useBackgroundProcessorsState(
		(selectors) => selectors.getProcessorLoadStatus(),
		[],
	);

	const strings = useStrings("BackgroundGallery", Object.values(TokenNames));

	// Change from grid to carousel when screen size reaches inflection points or on mobile device
	const windowSize = useWindowSize();
	const elementsPerPage = useMemo(() => {
		if (windowSize.width < SmallScreenWidthInflectionPoint) {
			return SmallInflectionElements;
		} else if (windowSize.width < ScreenWidthInflectionPoint) {
			return DesktopInflectionElements;
		}
		return DesktopElements;
	}, [windowSize.width]);

	const processorEntries = Array.from(processorMap.entries());

	// Enable NoBackground header if more than 1 background is loaded (more than just blurring access)
	const isNoneHeaderEnabled = processorEntries.length > 1;
	if (scrollGallery) {
		return processorLoadStatus !== "finished" ? (
			<Spinner />
		) : (
			<>
				<BackgroundGalleryOption
					previewStream={previewStream}
					image={DefaultImageNames.none}
					alt={strings[TokenNames.noneAriaLabel]}
					bannerText={strings[TokenNames.none]}
				/>
				{processorEntries.map(([path, processor], index) => {
					const altText = getAltForImage(path, processor, strings);
					return (
						<BackgroundGalleryOption
							key={index}
							previewStream={previewStream}
							image={path}
							alt={altText}
							bannerText={
								path === DefaultImageNames.blur ? strings[TokenNames.blur] : undefined
							}
							resourceType={processor.resourceType}
						/>
					);
				})}
			</>
		);
	}

	return (
		<MenuContextProvider isInMenu>
			<div role="menu">
				{previewStream && isNoneHeaderEnabled && (
					<BackgroundGalleryNoneOption
						previewStream={previewStream}
						ariaLabel={strings[TokenNames.noneAriaLabel]}
					/>
				)}
				<Carousel
					elementsPerPage={elementsPerPage}
					loading={processorLoadStatus !== "finished"}
					pageWrapperClassName={styles["backgroundOptionPage"]}
				>
					{previewStream && !isNoneHeaderEnabled && (
						<BackgroundGalleryOption
							previewStream={previewStream}
							image={DefaultImageNames.none}
							alt={strings[TokenNames.noneAriaLabel]}
							bannerText={strings[TokenNames.none]}
						/>
					)}
					{processorEntries.map(([path, processor], index) => {
						// Get image description from path
						const altText = getAltForImage(path, processor, strings);
						return (
							<BackgroundGalleryOption
								key={index}
								previewStream={previewStream}
								image={path}
								alt={altText}
								bannerText={
									path === DefaultImageNames.blur ? strings[TokenNames.blur] : undefined
								}
								resourceType={processor.resourceType}
							/>
						);
					})}
				</Carousel>
			</div>
		</MenuContextProvider>
	);
};

BackgroundGallery.displayName = "BackgroundGallery";

export default BackgroundGallery;
