/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Hardware test card contents
 * @author Max Harkins
 * @module Epic.VideoApp.Components.HardwareTest.HardwareCardContents
 */

import React, { FC, PropsWithChildren, ReactElement } from "react";
import { useWindowSize } from "~/hooks";
import { DisplayContext, ScreenWidthInflectionPoint } from "~/types";
import CopyrightLinkButton from "../Copyright/CopyrightLinkButton";
import { HardwareSetupDisplayContext } from "../HardwareSetup/HardwareSetup";
import HardwarePreviewPanel from "./HardwarePreviewPanel";
import styles from "./HardwareTest.module.scss";
import SettingsPanel from "./SettingsPanel";
import WelcomeHeading from "./WelcomeHeading";

interface IProps {
	mainButton: ReactElement;
	isLobby?: boolean;
}

/**
 * The HardwareTest component
 *
 * Holds the card contents for the hardware test
 *
 * @param props the props ;)
 */
const HardwareCardContents: FC<IProps> = (props: PropsWithChildren<IProps>) => {
	const { isLobby, mainButton } = props;

	const { width } = useWindowSize();

	return (
		<>
			<div className={styles["scrollContainer"]}>
				<WelcomeHeading isLobby={isLobby} />
				<div className={styles["expandingContents"]}>
					<HardwareSetupDisplayContext.Provider
						value={isLobby ? DisplayContext.lobby : DisplayContext.standAlone}
					>
						<HardwarePreviewPanel isLobby={isLobby}>
							{width > ScreenWidthInflectionPoint && mainButton}
						</HardwarePreviewPanel>
						<SettingsPanel />
						{width > ScreenWidthInflectionPoint ? null : <CopyrightLinkButton />}
					</HardwareSetupDisplayContext.Provider>
				</div>
			</div>
			{width <= ScreenWidthInflectionPoint && (
				<div className={styles["mainButtonContainer"]}>{mainButton}</div>
			)}
		</>
	);
};

HardwareCardContents.displayName = "HardwareCardContents";

export default HardwareCardContents;
